import React from 'react';
import styles from './HeroBlock.module.css';
import Image from 'next/image';
import { Typography } from 'aim-components';
import logo from '../../../public/svg/gilion-white-registered-trademark.svg';

interface HeroBlockProps {
  text?: string;
  children?: React.ReactNode;
  type?: 'chart' | 'chip';
}

const HeroBlock = ({ text, children, type = 'chart' }: HeroBlockProps) => {
  return (
    <div className={styles.heroBlockContainer}>
      <div className={styles.heroBlock}>
        <div className={styles.logo}>
          <Image src={logo} alt='Gilion' height={24} />
        </div>
        <div className={styles.image}>
          <Image
            src={type === 'chip' ? '/images/ram.png' : '/svg/gilion-block-chart.svg'}
            alt='Gilion illustration'
            fill
            className={styles.objectFit}
          />
        </div>
        {text && (
          <div className={styles.text}>
            <Typography variant='title2'>{text}</Typography>
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default HeroBlock;
