import { type FormEvent, useEffect, useState } from 'react';
import { ErrorScreen, toast } from 'aim-components';
import type { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { logger, useMobile } from 'aim-utils';
import useSWRImmutable from 'swr/immutable';
import { getLoginFlow, isLoginSuccess, type OryError, postLoginFlow, type PostLoginResponse } from 'aim-ory';
import ServiceDownError from '@/components/common/error/ServiceDownError';
import { getUserSession } from '@/lib/utils';
import { EntryWrapper } from '@/components/login/EntryWrapper';
import type { UiFlowResponse } from 'aim-ory/src/types';
import { SSOEntry } from '@/components/login/SSOEntry';
import { EntryForm } from '@/components/login/EntryForm';
import { ConnectToSSOAccountForm } from '@/components/login/ConnectToSSOAccountForm';
import HeroBlock from '@/components/common/heroBlock/HeroBlock';
import { SignupAndLoginLayout } from '@/components/login/SignupAndLoginLayout';

export type OryFlow = { status: number; data: UiFlowResponse };
export type AuthenticateMethod = 'password' | 'sso';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { userSession, intercomHash } = getUserSession(context);

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? '', ['login', 'common'])),
      userSession,
      intercomHash,
      host: (process.env.NODE_ENV === 'production' ? 'https://' : 'http://') + context.req.headers.host,
    },
  };
};

const isSafeRedirectUrl = (url: unknown): url is string => {
  if (typeof url !== 'string') return false;

  try {
    const destinationUrl = new URL(url);

    // ! For security reasons, we only allow redirects to the same origin as the current page.
    return destinationUrl.origin === window.location.origin;
  } catch {
    return false;
  }
};

const getDestinationUrlForActiveSession = (redirectUrlFromQueryParams: string | string[] | undefined): string => {
  if (redirectUrlFromQueryParams && isSafeRedirectUrl(redirectUrlFromQueryParams)) {
    return redirectUrlFromQueryParams;
  }

  return '/login-redirect';
};

const Login: NextPage<{ host: string }> = ({ host }) => {
  const { t } = useTranslation('common');
  const { t: tLogin } = useTranslation('login');
  const [loginMethod, setLoginMethod] = useState<AuthenticateMethod>('sso');
  const { mobileView } = useMobile();
  const router = useRouter();

  const {
    query: { flow: queryFlow, redirectTo },
  } = router;
  const {
    data: flow,
    mutate,
    error: swrError,
  } = useSWRImmutable(
    queryFlow
      ? // If a flow is provided we try to use that, otherwise we create a new one.
        `/self-service/login/flows?id=${queryFlow}`
      : `/self-service/login/browser?return_to=${redirectTo || host}`,
    getLoginFlow,
  );
  const [globalError, setGlobalError] = useState<string>();

  useEffect(() => {
    if (swrError && swrError.data?.id === 'session_already_available') {
      window.location.href = getDestinationUrlForActiveSession(redirectTo);
      return;
    }
    if (swrError && swrError.data?.id === 'self_service_flow_expired') {
      window.location.replace('/login');
      return;
    }
    if (swrError) {
      logger.error({ message: 'Unhandled Ory error', error: swrError });
      setGlobalError('Something went wrong, please try again later.');
    }
  }, [swrError, redirectTo]);

  /* This effect is for an extremely rare edge case (that Elin experienced the first day...) where the user has the login page open for a long time
  and then tries to log in. The flow is then expired and the user gets stuck on the login page. By checking when the page is refocused we can
  detect if more than 20 minutes has passed and then get a new flow (the Ory expiration time is actually 30 minutes, but we add a bit of margin).
  If you keep the login page open and just stare at it for 30 minutes the flow will still expire since no focus event is fired, but there's a limit
  to how much we can compensate for the user 😅 */
  useEffect(() => {
    const lastViewed = new Date().getTime();
    const onFocus = () => {
      // If refocused after 20 minutes we get a new flow
      if (new Date().getTime() - lastViewed > 1000 * 60 * 20) {
        mutate();
      }
    };
    window.addEventListener('focus', onFocus);

    return () => window.removeEventListener('focus', onFocus);
  }, [mutate]);

  // This is the only way to figure out what kind of flow it is since the login flows are all the same.
  // This message is explicitly about the user having to verify their credentials.
  const connectExistingUserAccountToSSO = flow?.data.messages[0]?.id === 4000007;

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const form = event.currentTarget;

    if (flow && form && form instanceof HTMLFormElement) {
      const formData = new FormData(form);
      formData.set('method', 'password');
      formData.set('refresh', 'true');

      const body = Object.fromEntries(formData);

      let response: PostLoginResponse;

      // The client only throws on unrecoverable errors, normal errors are returned as a flow and can be continued.
      // This means this is NOT to be seen as the general error handling but rather a "this should not happen" error.
      try {
        response = await postLoginFlow(flow.data.id, body);
      } catch (error) {
        logger.error({
          message: `Post login flow error, isConnectingExistingAccountToSso: ${connectExistingUserAccountToSSO}`,
          error: error as Error,
        });
        toast({ message: (error as OryError).data.message, type: 'critical' });

        return;
      }

      // Here type narrowing works because the response.data type can be either a flow or a session response.
      if (isLoginSuccess(response)) {
        window.location.href = getDestinationUrlForActiveSession(redirectTo);
        return;
      }

      // Normal errors would show up in the UI flow and those are handled here.
      mutate(response, { revalidate: false });

      if (response.data.messages.length > 0) {
        toast({ message: response.data.messages[0].text, type: 'critical' });
      }
    }
  };

  const toggleLoginMethod = () => {
    setLoginMethod((prev: AuthenticateMethod) => (prev === 'password' ? 'sso' : 'password'));
  };

  if (!flow) return null;

  if (globalError) {
    return (
      <ErrorScreen title={t('errorScreen.common.title')} logo='/images/gilion_white.png'>
        <ServiceDownError />
      </ErrorScreen>
    );
  }

  if (connectExistingUserAccountToSSO) {
    if (mobileView) {
      return (
        <SignupAndLoginLayout>
          <ConnectToSSOAccountForm flow={flow} onSubmit={onSubmit} />
        </SignupAndLoginLayout>
      );
    }
    return (
      <SignupAndLoginLayout>
        <HeroBlock text={tLogin('heroBlockText')}>
          <EntryWrapper title={tLogin('welcomeBackTitle')}>
            <ConnectToSSOAccountForm flow={flow} onSubmit={onSubmit} />
          </EntryWrapper>
        </HeroBlock>
      </SignupAndLoginLayout>
    );
  }

  if (mobileView) {
    return (
      <SignupAndLoginLayout>
        {loginMethod === 'sso' && (
          <SSOEntry flow={flow} toggleAuthenticateMethod={toggleLoginMethod} entryType='login' />
        )}
        {loginMethod === 'password' && (
          <EntryForm flow={flow} onSubmit={onSubmit} toggleAuthenticateMethod={toggleLoginMethod} entryType='login' />
        )}
      </SignupAndLoginLayout>
    );
  }

  return (
    <SignupAndLoginLayout image='analytics'>
      {loginMethod === 'sso' && <SSOEntry flow={flow} toggleAuthenticateMethod={toggleLoginMethod} entryType='login' />}
      {loginMethod === 'password' && (
        <EntryForm flow={flow} onSubmit={onSubmit} toggleAuthenticateMethod={toggleLoginMethod} entryType='login' />
      )}
    </SignupAndLoginLayout>
  );
};

export default Login;
