import { Button, Typography } from 'aim-components';
import { EntryInputFields } from './EntryInputFields';
import { useTranslation } from 'next-i18next';
import type { OryFlow } from 'pages/login/index.page';
import { useState, type FormEvent } from 'react';
import styles from './ConnectToSSOAccountForm.module.css';
import { oryNode } from 'aim-ory';

interface ConnectToSSOAccountFormProps {
  flow: OryFlow;
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const ConnectToSSOAccountForm = ({ flow, onSubmit }: ConnectToSSOAccountFormProps) => {
  const { t } = useTranslation('login');
  const [filledOutForm, setFilledOutForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    await onSubmit(event);
  };

  return (
    <div className={styles.container}>
      <Typography variant='text1' className={styles.left}>
        {t('welcomeBack')}
      </Typography>
      <form action={flow.data.action} method={flow.data.method} onSubmit={submitForm}>
        <EntryInputFields flow={flow} entryType='login' setFilledOutForm={setFilledOutForm} />
        <div className={styles.button}>
          <Button
            name={oryNode(flow.data.nodes.method).attributes.name}
            value={oryNode(flow.data.nodes.method).attributes.value}
            buttonType='submit'
            endIcon='arrow-right'
            disabled={!filledOutForm}
            loading={loading}
          >
            {t('connectAndLogin')}
          </Button>
        </div>
      </form>
    </div>
  );
};
